<script setup lang="ts">
import type { ExternalUser } from '../../../auth/entities/user';
import { useUserInfo } from '../../composables/userInfo';
import type { SelectableItem } from '../../types/base';
import type {
    ProductRequest,
    ProductRequestErrors,
} from '../../types/contract';

type Props = {
    showModal?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
    showModal: false,
});

const emit = defineEmits(['close', 'submit']);

const { t } = useLang();
const { userInfo } = useUserInfo();
const { user, isExternalUser } = useUser();
const { checkHtmlTags } = useUtilFunctions();
const { MAX_CHARACTERS_TITLE, EMAIL_PATTERN, MAX_CHARACTERS_DEFAULT } =
    useConstants();
const isMdScreen = useBreakpoint(BREAKPOINTS.md);
const { selectableDemandMagnitudeList } = useProduct();
const formContainer = ref<HTMLElement | null>(null);
const showShadow = ref<boolean>(false);

const setDefaultFormValues = (): ProductRequest => {
    const assignedSalesUser = isExternalUser.value
        ? (user.value as ExternalUser).assignedSalesUser
        : null;

    return {
        assignedSalesUser: assignedSalesUser
            ? assignedSalesUser.firstName + ' ' + assignedSalesUser.lastName
            : '',
        email: user.value?.email || '',
        firstName: userInfo.value?.firstName || '',
        lastName: userInfo.value?.lastName || '',
        magnitude: undefined,
        message: '',
        phoneNumber: '',
        salutation: 'w',
        title: '',
    };
};
const productRequest = ref<ProductRequest>(setDefaultFormValues());

const setDefaultErrorValues = () => {
    return {
        email: '',
        firstName: '',
        lastName: '',
        message: '',
        phoneNumber: '',
        title: '',
    };
};
const productRequestErrors = ref<ProductRequestErrors>(setDefaultErrorValues());

const show = ref<boolean>(false);

const onClose = (): void => {
    productRequest.value = setDefaultFormValues();
    productRequestErrors.value = setDefaultErrorValues();
    show.value = false;
    emit('close');
};

const setDemandMagnitude = (selectedDemand: SelectableItem): void => {
    if (selectedDemand.id !== productRequest.value?.magnitude) {
        productRequest.value.magnitude = selectedDemand.id;
    }
};

const validateTitle = (): void => {
    const title = productRequest.value.title;
    if (title) {
        if (title.length <= MAX_CHARACTERS_TITLE) {
            if (checkHtmlTags(title)) {
                productRequestErrors.value.title = '';
            } else {
                productRequestErrors.value.title = t(
                    'validation',
                    'invalidCharacterCombination',
                );
            }
        } else {
            productRequestErrors.value.title = t(
                'validation',
                'maxLengthCharacters',
                { length: MAX_CHARACTERS_TITLE },
            );
        }
    } else {
        productRequestErrors.value.title = '';
    }
};

const validateText = (
    errorField: 'firstName' | 'lastName' | 'phoneNumber',
    text?: string,
): void => {
    if (text) {
        if (text.length <= MAX_CHARACTERS_TITLE) {
            if (checkHtmlTags(text)) {
                productRequestErrors.value[errorField] = '';
            } else {
                productRequestErrors.value[errorField] = t(
                    'validation',
                    'invalidCharacterCombination',
                );
            }
        } else {
            productRequestErrors.value[errorField] = t(
                'validation',
                'maxLengthCharacters',
                { length: MAX_CHARACTERS_TITLE },
            );
        }
    } else {
        productRequestErrors.value[errorField] = t(
            'validation',
            'notEmptyField',
        );
    }
};

const validateEmail = (): void => {
    if (productRequest.value.email) {
        if (productRequest.value.email.length <= MAX_CHARACTERS_TITLE) {
            if (productRequest.value.email.match(EMAIL_PATTERN)) {
                if (checkHtmlTags(productRequest.value.email)) {
                    productRequestErrors.value.email = '';
                } else {
                    productRequestErrors.value.email = t(
                        'validation',
                        'invalidCharacterCombination',
                    );
                }
            } else {
                productRequestErrors.value.email = t(
                    'validation',
                    'emailInvalid',
                );
            }
        } else {
            productRequestErrors.value.email = t(
                'validation',
                'maxLengthCharacters',
                { length: MAX_CHARACTERS_TITLE },
            );
        }
    } else {
        productRequestErrors.value.email = t('validation', 'notEmptyField');
    }
};

const validateMessage = (): void => {
    if (productRequest.value.message) {
        const normalizedValue = productRequest.value.message.replace(
            /\n/g,
            '\r\n',
        );
        if (normalizedValue.length <= MAX_CHARACTERS_DEFAULT) {
            if (checkHtmlTags(productRequest.value.message)) {
                productRequestErrors.value.message = '';
            } else {
                productRequestErrors.value.message = t(
                    'validation',
                    'invalidCharacterCombination',
                );
            }
        } else {
            productRequestErrors.value.message = t(
                'validation',
                'maxLengthCharacters',
                { length: MAX_CHARACTERS_DEFAULT },
            );
        }
    } else {
        productRequestErrors.value.message = '';
    }
};

const productRequestDataValid = computed(
    () =>
        productRequest.value.firstName.length > 0 &&
        productRequest.value.lastName.length > 0 &&
        productRequest.value.email.length > 0 &&
        productRequest.value.phoneNumber.length > 0,
);

const onScroll = (): void => {
    if (formContainer.value) {
        showShadow.value = formContainer.value.scrollTop > 0;
    }
};

watchEffect(() => {
    show.value = props.showModal;
});

watch(
    () => productRequest.value.title,
    () => validateTitle(),
);

watch(
    () => productRequest.value.firstName,
    () => {
        validateText('firstName', productRequest.value.firstName);
    },
);

watch(
    () => productRequest.value.lastName,
    () => {
        validateText('lastName', productRequest.value.lastName);
    },
);

watch(
    () => productRequest.value.email,
    () => validateEmail(),
);

watch(
    () => productRequest.value.phoneNumber,
    () => {
        validateText('phoneNumber', productRequest.value.phoneNumber);
    },
);

watch(
    () => productRequest.value.message,
    () => validateMessage(),
);

onMounted(() => {
    formContainer.value?.addEventListener('scroll', onScroll);
});
</script>

<template>
    <MtbaseModal
        :width="isMdScreen ? 'md:w-[46rem]' : 'w-full'"
        padding="pt-[2.75rem] pb-13"
        :show="show"
        :full-height="!isMdScreen"
        :full-screen="!isMdScreen"
        @close="onClose"
    >
        <div class="flex max-h-full flex-col">
            <div
                class="px-[2.75rem] pb-5"
                :class="{
                    'z-10 shadow-bottom': showShadow,
                }"
            >
                <span class="text-xl font-semibold">
                    {{ t('label', 'requestProduct') }}
                </span>
            </div>
            <div ref="formContainer" class="overflow-auto">
                <div
                    data-testid="ProductRequestModal"
                    class="flex h-full flex-col px-[2.75rem] pb-[1.5rem]"
                >
                    <div class="mt-5 flex flex-col">
                        <div class="md:flex md:flex-row md:gap-5">
                            <div class="flex flex-col md:w-1/2">
                                <MtbaseFormSingleSelect
                                    class="w-full"
                                    :entries="selectableDemandMagnitudeList"
                                    :close-on-select="true"
                                    :label="t('input', 'selectStaffingNeeds')"
                                    :form-label="t('label', 'staffingNeeds')"
                                    @selection="setDemandMagnitude"
                                />
                            </div>
                        </div>
                        <div class="hidden md:block md:w-1/2"></div>
                    </div>
                    <div class="mt-5 flex flex-col">
                        <div class="md:flex md:flex-row md:gap-5">
                            <div class="flex flex-col md:w-1/2">
                                <div>
                                    <BaseFormLabel
                                        :text="t('input', 'salutation')"
                                    />
                                    <div class="flex h-full">
                                        <BaseRadio
                                            name="salutation"
                                            :model-value="
                                                productRequest.salutation
                                            "
                                            value="w"
                                            @update:model-value="
                                                productRequest.salutation =
                                                    $event
                                            "
                                        >
                                            {{ t('label', 'salutationMs') }}
                                        </BaseRadio>
                                        <BaseRadio
                                            name="salutation"
                                            class="ml-5"
                                            :model-value="
                                                productRequest.salutation
                                            "
                                            value="m"
                                            @update:model-value="
                                                productRequest.salutation =
                                                    $event
                                            "
                                        >
                                            {{ t('label', 'salutationMr') }}
                                        </BaseRadio>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 flex flex-col md:mt-0 md:w-1/2">
                                <BaseFormLabel
                                    :text="t('input', 'titleOptional')"
                                />
                                <MtbaseFormInput
                                    class="h-full rounded-t p-4"
                                    :model-value="productRequest.title"
                                    :placeholder="t('input', 'titleOptional')"
                                    :gray="true"
                                    :border-bottom="true"
                                    :error-msg="productRequestErrors?.title"
                                    @on-change="productRequest.title = $event"
                                />
                            </div>
                        </div>
                        <div class="mt-5 md:flex md:flex-row md:gap-5">
                            <div class="flex flex-col md:w-1/2">
                                <BaseFormLabel
                                    :text="t('input', 'firstName')"
                                />
                                <MtbaseFormInput
                                    class="h-full max-h-[2.95rem] rounded-t p-4"
                                    :model-value="productRequest.firstName"
                                    :placeholder="t('input', 'firstName')"
                                    :gray="true"
                                    :border-bottom="true"
                                    :error-msg="productRequestErrors?.firstName"
                                    @on-change="
                                        productRequest.firstName = $event
                                    "
                                />
                            </div>
                            <div class="mt-5 flex flex-col md:mt-0 md:w-1/2">
                                <BaseFormLabel :text="t('input', 'lastName')" />
                                <MtbaseFormInput
                                    class="h-full max-h-[2.95rem] rounded-t p-4"
                                    :model-value="productRequest.lastName"
                                    :placeholder="t('input', 'lastName')"
                                    :gray="true"
                                    :border-bottom="true"
                                    :error-msg="productRequestErrors?.lastName"
                                    @on-change="
                                        productRequest.lastName = $event
                                    "
                                />
                            </div>
                        </div>
                        <div class="mt-5 md:flex md:flex-row md:gap-5">
                            <div class="flex flex-col md:w-1/2">
                                <BaseFormLabel :text="t('input', 'email')" />
                                <MtbaseFormInput
                                    class="h-full max-h-[2.95rem] rounded-t p-4"
                                    :model-value="productRequest.email"
                                    :placeholder="t('input', 'email')"
                                    :gray="true"
                                    :border-bottom="true"
                                    :error-msg="productRequestErrors?.email"
                                    @on-change="productRequest.email = $event"
                                />
                            </div>
                            <div class="mt-5 flex flex-col md:mt-0 md:w-1/2">
                                <BaseFormLabel
                                    :text="t('input', 'phoneNumber')"
                                />
                                <MtbaseFormInput
                                    class="h-full max-h-[2.95rem] rounded-t p-4"
                                    :model-value="productRequest.phoneNumber"
                                    :placeholder="t('input', 'phoneNumber')"
                                    :gray="true"
                                    :border-bottom="true"
                                    :error-msg="
                                        productRequestErrors?.phoneNumber
                                    "
                                    @on-change="
                                        productRequest.phoneNumber = $event
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <BaseFormLabel
                            :text="t('input', 'messageBodyOptional')"
                        />
                        <MtbaseFormTextArea
                            :value="productRequest.message"
                            :placeholder="t('input', 'messageBodyOptional')"
                            :error-msg="productRequestErrors?.message"
                            :show-error="!!productRequestErrors?.message"
                            @on-change="productRequest.message = $event"
                        />
                        <div v-if="productRequestErrors?.message">
                            <BaseErrorMessage
                                :message="productRequestErrors.message"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="absolute bottom-0 left-0 w-full bg-white px-6 py-[1rem] shadow-top lg:px-[2.75rem]"
                >
                    <div class="float-right">
                        <MtbaseButton
                            :primary="true"
                            class="px-5"
                            size="auto"
                            :disabled="!productRequestDataValid"
                            @click="$emit('submit', productRequest)"
                        >
                            {{ t('button', 'sendProductRequest') }}
                        </MtbaseButton>
                    </div>
                </div>
            </div>
        </div>
    </MtbaseModal>
</template>
