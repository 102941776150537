import type { SelectableItem } from '../types/base';
import type { ProductRequest } from '../types/contract';

const ENDPOINT = '/api/manage-talents/contact-form/product-request';
const { t } = useLang();

const selectableDemandMagnitudeList = ref<Array<SelectableItem>>([
    {
        id: 0,
        label: t('label', 'staffingDemandLower10'),
    },
    {
        id: 1,
        label: t('label', 'staffingDemandBetween10And50'),
    },
    {
        id: 2,
        label: t('label', 'staffingDemandOver50'),
    },
]);

export const useProduct = () => {
    const sendProductRequest = async (
        productRequest: ProductRequest,
    ): Promise<boolean> => {
        const formData = new FormData();
        formData.append('contactEmails', productRequest.email || '');
        formData.append(
            'customerName',
            productRequest.title +
                ' ' +
                productRequest.firstName +
                ' ' +
                productRequest.lastName,
        );
        formData.append(
            'customerContactPerson',
            productRequest?.assignedSalesUser,
        );

        const messageData = productRequest.message
            ? 'Nachricht: ' + productRequest.message
            : '';
        const phoneData = productRequest.phoneNumber
            ? ' | Tel: ' + productRequest.phoneNumber
            : '';
        const magnitudeData =
            productRequest.magnitude !== undefined
                ? ' | Personalbedarf: ' +
                  selectableDemandMagnitudeList.value.find(
                      (magnitude) => productRequest.magnitude === magnitude.id,
                  )?.label
                : '';
        formData.append('message', messageData + phoneData + magnitudeData);

        return fetchStatusOrRedirect(ENDPOINT, {
            body: formData,
            method: 'POST',
        });
    };

    return {
        selectableDemandMagnitudeList,
        sendProductRequest,
    };
};
